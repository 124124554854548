import * as React from 'react';

import AuthStore from './AuthStore';
import { AuthStoreProvider, AuthStoreContext } from './context';

const useAuthStore = (): AuthStore => {
  const authStoreContext = React.useContext(AuthStoreContext);

  return authStoreContext.store as AuthStore;
};

export { AuthStore, useAuthStore, AuthStoreProvider };
