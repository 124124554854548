import * as React from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/named
import { throttle } from 'lodash';

import { Button } from 'shared/newComponents/buttons';
import { TranslationNode } from 'shared/entities/localization';
import {
  ButtonColor,
  ButtonProps,
  ButtonSize
} from 'shared/newEntities/components/Button';
import { useKeyboardEventsStore } from 'stores/index';
import {
  KeyboardEventListener,
  KeyboardEventType
} from 'shared/entities/keyboardEvents';
import TransComp from 'shared/components/TransComp';

import './Content.modules.scss';

type Props = React.PropsWithChildren<{
  ok: ButtonProps;
  className?: string;
  rightFooter?: boolean;
  footnotes?: TranslationNode;
  cancel?: ButtonProps;
  opened: boolean;
  onEnter?: () => void;
  withHideScrollBar?: boolean;
  // расстояние, которое нужно проскролить до полной прокрутки
  bottomDistanceForScrollToBottom?: number;
}>;

const Content: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  rightFooter,
  footnotes,
  cancel,
  opened,
  withHideScrollBar = true,
  bottomDistanceForScrollToBottom
}: Props) => {
  const keyboardEventsStore = useKeyboardEventsStore();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const [contentHeight, setContentHeight] = React.useState<number>(0);

  const handleEnter = React.useCallback<KeyboardEventListener>(() => {
    onEnter?.();

    return true;
  }, [onEnter]);

  React.useEffect(() => {
    if (opened) {
      keyboardEventsStore.addListener({
        type: KeyboardEventType.enter,
        listener: handleEnter
      });
    }

    return () => {
      if (opened) {
        keyboardEventsStore.removeListener({
          type: KeyboardEventType.enter,
          listener: handleEnter
        });
      }
    };
  }, [handleEnter, opened]);

  const scrollTo = React.useCallback(() => {
    if (
      !contentRef.current ||
      !containerRef.current ||
      !bottomDistanceForScrollToBottom ||
      !contentHeight
    ) {
      return;
    }
    // высота контента увеличилась
    const contentHeightIncreased =
      contentRef.current.clientHeight > contentHeight;
    // до конца контента осталось проскроллить < bottomDistanceForScrollToBottom
    const scrolledToBottom =
      containerRef.current.scrollHeight - containerRef.current.scrollTop <
      containerRef.current.clientHeight + bottomDistanceForScrollToBottom;

    //скроллим до конца
    if (contentHeightIncreased && scrolledToBottom) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [bottomDistanceForScrollToBottom, contentHeight]);

  React.useEffect(() => {
    return () => {
      const handler = throttle(scrollTo, 500);
      handler();
    };
  }, [contentHeight]);

  React.useEffect(() => {
    let observer: ResizeObserver | null = null;

    const setHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    };

    if (contentRef.current && bottomDistanceForScrollToBottom) {
      observer = new ResizeObserver(setHeight);

      observer.observe(contentRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [opened]);

  const buttonsOnTheRight = !!cancel && rightFooter;

  return (
    <>
      <div styleName="base-form-modal__content">
        <div
          styleName={cn(
            'base-form-modal__content-container',
            withHideScrollBar && 'base-form-modal__content-container_hide'
          )}
          ref={containerRef}
        >
          <div ref={contentRef}>{children}</div>
        </div>
      </div>
      <div
        styleName={cn(
          'base-form-modal__footer',
          cancel && 'base-form-modal__footer_two-button',
          buttonsOnTheRight && 'base-form-modal__footer_two-button_right'
        )}
      >
        {footnotes && (
          <div styleName="base-form-modal__footer-footnotes">
            <TransComp>{footnotes}</TransComp>
          </div>
        )}
        {cancel && (
          <Button
            {...{
              ...cancel,
              color: cancel.color || ButtonColor.gray,
              size: buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md,
              children:
                cancel.children ||
                ((t) =>
                  t('FormModal.cancel', {
                    ns: 'newComponents'
                  }))
            }}
          />
        )}
        <Button
          {...ok}
          color={ButtonColor.blue}
          fullWidth={!cancel}
          size={buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md}
        >
          {ok.children ||
            ((t) =>
              t('FormModal.ok', {
                ns: 'newComponents'
              }))}
        </Button>
      </div>
    </>
  );
};

export default React.memo(Content);
