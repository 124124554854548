import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';
import { CommonDirection } from 'shared/entities/common/client';

import styles from './DoubleArrowIcon.modules.scss';

type Props = CommonIconProps & {
  direction?: CommonDirection;
};

const DoubleArrowIcon: React.FC<Props> = ({
  direction = CommonDirection.bottom,
  ...rest
}: Props) => {
  return (
    <BaseIcon
      iconSize={IconSize.XSS}
      svgProps={{
        viewBox: '0 0 8 10'
      }}
      {...rest}
      styleName="double-arrow-icon"
      className={styles[`double-arrow-icon_${direction}`]}
    >
      <path
        d="M0.666656 5.66667L3.99999 9L7.33332 5.66667M0.666656 1L3.99999 4.33333L7.33332 1"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default DoubleArrowIcon;
