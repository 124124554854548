import { observer } from 'mobx-react';
import * as React from 'react';

import SelectorBase from 'shared/newComponents/SelectorBase';
import { useVKCreationStore } from 'stores/VKCreationStore';

import Trigger from './Trigger';
import Content from './Content';

type Props = {
  className?: string;
};

const VKAccount: React.FC<Props> = ({ className }) => {
  const vkCreationStore = useVKCreationStore();

  return (
    <SelectorBase
      className={className}
      disabled={!vkCreationStore.vkAccount}
      label={(t) =>
        t('ChannelsModal.ChannelForm.VK.VKAccount.label', {
          ns: 'newContainers'
        })
      }
      trigger={<Trigger />}
      optionsLength={1}
    >
      {(onClose) => <Content onClose={onClose} />}
    </SelectorBase>
  );
};

export default observer(VKAccount);
