import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const WazzupIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{ viewBox: '0 0 48 48' }}
      {...rest}
    >
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill={color === 'original' ? '#4CAF50' : 'currentColor'}
      />
      <path
        d="M28 17L23.5303 31H20.8716L17.5385 20.64L14.1477 31H11.4697L7 17H9.60092L12.9532 27.64L16.4404 17H18.7523L22.1624 27.7L25.6109 17H28Z"
        fill="white"
      />
      <path
        d="M40.5 29V31H29.5V29L37.5 19H29.5V17H40.5V19L32.5 29H40.5Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export const WazzupOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <WazzupIcon color="original" {...props} />
);

export const WazzupTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <WazzupIcon color="transparent" {...props} />
);
