import { createContext } from 'react';

import AuthStore from './AuthStore';

const AuthStoreContext = createContext<{
  store: AuthStore | null;
}>({
  store: null
});

const AuthStoreProvider = AuthStoreContext.Provider;

export { AuthStoreContext, AuthStoreProvider };
