import * as React from 'react';
import { observer } from 'mobx-react';

import { useDebouncedEffect } from 'shared/entities/common/hooks';
import {
  SelectorKey,
  SelectorSortOrder,
  SelectorType
} from 'shared/entities/components/Selector';
import {
  renderTranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import { SelectorProps } from 'shared/newEntities/components/Selector';
import SelectorBase from 'shared/newComponents/SelectorBase';

import SelectedOption from './SelectedOption';
import Content from './Content';
import Trigger from './Trigger';

import './Selector.modules.scss';

const DEBOUNCE_DELAY = 500;

const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const { t, lng } = useTypedTranslation();

  const {
    placeholder,
    pluralize,
    loadingStage,
    options,
    onClose,
    title,
    className,
    TriggerIcon,
    ...rest
  } = props;
  const [searchText, setSearchText] = React.useState('');
  const [actualOptionsIds, setActualOptionsIds] = React.useState([
    ...options.keys
  ]);

  const entities = React.useMemo(() => {
    return Object.keys(options.entities).reduce((acc, key) => {
      const item = options.entities[key];

      if (!item) {
        return acc;
      }

      return {
        ...acc,
        [key]: {
          ...item,
          title: renderTranslationString(item.title, t),
          description: item.description
            ? renderTranslationString(item.description, t)
            : item.description,
          id: item.id
        }
      };
    }, {});
  }, [lng, options.entities, options.keys, options.keys.length]);

  const sortOrder =
    props.sortOrder === null
      ? null
      : props.sortOrder
      ? props.sortOrder
      : SelectorSortOrder.asc;

  const filterOptions = React.useCallback(() => {
    let ids: SelectorKey[] = [];

    if (sortOrder) {
      ids = [...options.keys].sort((firstId, secondId) => {
        const firstMoreThanSecond =
          entities[firstId]?.title.toLowerCase?.() >
          entities[secondId]?.title.toLowerCase?.();

        if (sortOrder === SelectorSortOrder.asc) {
          return firstMoreThanSecond ? 1 : -1;
        }
        return firstMoreThanSecond ? -1 : 1;
      });
    } else {
      ids = options.keys;
    }

    setActualOptionsIds(
      ids.filter((item) => {
        const option = entities[item];

        return option
          ? String(option.title)
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      })
    );
  }, [searchText, sortOrder, entities, options.keys]);

  React.useEffect(filterOptions, [options.keys, options.keys.length, entities]);

  const handleOptionClick = React.useCallback(
    (id: SelectorKey) => {
      if (props.type !== SelectorType.multi) {
        props.onChange(id);
        return;
      }

      if (props.value.includes(id)) {
        props.onChange(props.value.filter((selectedId) => selectedId !== id));
      } else {
        props.onChange([id, ...props.value]);
      }
    },
    [props.onChange, props.value, props.type]
  );

  const handleRemove = React.useCallback(() => {
    if (props.type === SelectorType.multi) {
      return;
    }

    props.onChange(null);
  }, [props.onChange, props.type]);

  useDebouncedEffect(filterOptions, DEBOUNCE_DELAY, [searchText]);

  const handleClosePopup = React.useCallback(() => {
    setSearchText('');
    onClose?.();
  }, [onClose]);

  const singleEntity =
    rest.type === SelectorType.single && rest.value
      ? options.entities[rest.value]
      : null;

  const actualOptions = {
    keys: actualOptionsIds,
    entities
  };

  return (
    <SelectorBase
      {...rest}
      onClose={handleClosePopup}
      className={className}
      optionsLength={actualOptionsIds.length}
      trigger={(isOpen) => (
        <Trigger
          options={actualOptions}
          isSelectorOpened={isOpen}
          searchText={searchText}
          onChangeSearchText={setSearchText}
          pluralize={pluralize}
          placeholder={placeholder}
          title={title}
          Icon={TriggerIcon}
          {...rest}
        />
      )}
      contentTopNode={
        rest.type === SelectorType.single &&
        rest.withRemoving !== false &&
        singleEntity && (
          <SelectedOption {...singleEntity} onRemove={handleRemove} />
        )
      }
    >
      {(onClose) => (
        <Content
          {...rest}
          onClose={onClose}
          options={actualOptions}
          loadingStage={loadingStage}
          onOptionClick={handleOptionClick}
        />
      )}
    </SelectorBase>
  );
};

export default observer(Selector);
