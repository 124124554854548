import * as React from 'react';
import { observer } from 'mobx-react';

import { usePluginsStore, useRouterStore, useUiStore } from 'stores/index';
import { ComponentType } from 'shared/entities/components/Component';
import { useTypedTranslation } from 'shared/entities/localization';
import FormModal from 'shared/newComponents/modals/FormModal';
import { noop } from 'shared/entities/common/utils';

const AuthModal: React.FC = () => {
  const uiStore = useUiStore();
  const pluginsStore = usePluginsStore();
  const { t } = useTypedTranslation();
  const routerStore = useRouterStore();

  const handleClick = React.useCallback(() => {
    if (!pluginsStore.linkToAuth) {
      routerStore.changeToAuthDomain();
    } else {
      window.location.href = pluginsStore.linkToAuth;
    }
  }, [pluginsStore.linkToAuth]);

  return (
    <FormModal
      withHeader={false}
      opened={uiStore.isAuthorizedModalOpen}
      onClose={noop}
      ok={{
        children: (t) =>
          t('buttonTitle', {
            ns: 'AuthModal'
          }),
        type: ComponentType.button,
        onClick: handleClick
      }}
      withoutCancelButton
    >
      {t('content', {
        ns: 'AuthModal'
      })}
    </FormModal>
  );
};

export default observer(AuthModal);
