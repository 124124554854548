import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  reaction
} from 'mobx';

import {
  apiUrls,
  AuthStage,
  getUrlFromPathAndParamsObject,
  urls
} from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { LoadingStage } from 'shared/entities/meta';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { TgAuthFuncData } from 'shared/entities/tgAuth';
import { ChannelKind, channelKindEntities } from 'shared/entities/channels';
import {
  LoginErrors,
  mapRegistrationErrorToMessage,
  RegistrationErrors,
  mapBaseLoginAuthErrors,
  OAuthCommonError,
  BaseAuthLoginErrors,
  mapOAuthTgErrorsToMessage,
  OAuthTgErrors,
  mapLoginErrorToMessage,
  IOauthInfo,
  normalizeOauthInfo,
  OauthInfoServer,
  RegistrationStages
} from 'shared/entities/user';
import { LoadingStageModel } from 'shared/models/loadingStage';
import CabinetCreationStore from 'stores/cabinetCreationStore/CabinetCreationStore';
import { FieldModel } from 'shared/models/form';
import UserEditModel from 'shared/models/users/UserEditModel';
import SmartCaptchaModel from 'shared/models/captcha';
import PubSubObserver from 'lib/PubSubObserver';
import {
  DomainCheckingError,
  mapDomainCheckingErrorToMessage
} from 'shared/entities/cabinet';
import { generateId } from 'shared/entities/common/utils';
import { ComponentType } from 'shared/entities/components/Component';
import { ButtonSize } from 'shared/newEntities/components/Button';

const BANNER_ID = '__TG_AUTH_BANNER__';

export default class AuthStore extends PubSubObserver {
  rootStore: IRootStore;
  cabinetCreationStore: CabinetCreationStore;

  readonly registrationStage: FieldModel<RegistrationStages> =
    new FieldModel<RegistrationStages>(RegistrationStages.REGISTRATION_START);
  requestTgAuthStage: LoadingStage = LoadingStage.NOT_STARTED;
  readonly registerStage: LoadingStageModel = new LoadingStageModel();

  readonly registered: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly requestOauthLinksStage: LoadingStageModel = new LoadingStageModel();
  readonly oauthInfo: FieldModel<IOauthInfo | null> =
    new FieldModel<IOauthInfo | null>(null);
  readonly loginEditModel: UserEditModel = new UserEditModel();
  readonly registerEditModel: UserEditModel = new UserEditModel();
  readonly loginCaptcha: SmartCaptchaModel = new SmartCaptchaModel();
  readonly checkingDomainStage: LoadingStageModel = new LoadingStageModel();
  readonly registerAndCabinetCreationStage: LoadingStageModel =
    new LoadingStageModel();
  private readonly id: string;

  constructor({ rootStore }: { rootStore: IRootStore }) {
    super();
    this.rootStore = rootStore;
    this.cabinetCreationStore = new CabinetCreationStore({
      rootStore,
      newUser: true
    });
    this.id = generateId();

    makeObservable(this, {
      requestTgAuthStage: observable,

      requestTgAuth: action,
      handleTgAuthResponse: action,
      login: action.bound,
      startRegister: action.bound,

      registerButtonDisabled: computed
    });
  }

  get registerButtonDisabled(): boolean {
    return (
      (!this.registered.value && this.registerEditModel.isError) ||
      this.cabinetCreationStore.buttonDisabled ||
      this.registerAndCabinetCreationStage.isLoading
    );
  }

  get loginButtonDisabled(): boolean {
    return (
      this.loginEditModel.email.isError || this.loginEditModel.password.isError
    );
  }

  get isLogin(): boolean {
    return this.rootStore.routerStore.matchPath(
      urls.AUTH.create(AuthStage.LOGIN)
    );
  }

  get isRegistration(): boolean {
    return this.rootStore.routerStore.matchPath(
      urls.AUTH.create(AuthStage.REGISTER)
    );
  }

  get isRegisterStart(): boolean {
    return (
      this.isRegistration &&
      this.registrationStage.value === RegistrationStages.REGISTRATION_START
    );
  }

  get isCabinetCreation(): boolean {
    return (
      this.isRegistration &&
      this.registrationStage.value === RegistrationStages.CABINET_CREATION
    );
  }

  requestOauthLinks = async (): Promise<BaseResponse> => {
    if (this.requestOauthLinksStage.isLoading) {
      return {
        isError: true
      };
    }

    this.requestOauthLinksStage.loading();

    const response = await this.rootStore.networkStore.api<OauthInfoServer>(
      apiUrls.OAUTH_LIST_LOGIN_URLS
    );

    if (response.isError) {
      this.requestOauthLinksStage.error();
    } else {
      this.oauthInfo.changeValue(normalizeOauthInfo(response.data));
      this.requestOauthLinksStage.success();
    }

    return response;
  };

  changeStageToRegistration(): void {
    this.rootStore.routerStore.push(urls.AUTH.create(AuthStage.REGISTER));
    this.registerReset();
  }

  changeStageToLogin = (): void => {
    this.rootStore.routerStore.push(urls.AUTH.create(AuthStage.LOGIN));
    this.loginEditModel.reset();
  };

  changeStageToCabinetCreation = (): void => {
    this.registerEditModel.validate();

    if (!this.registerEditModel.isError) {
      this.registrationStage.changeValue(RegistrationStages.CABINET_CREATION);
    }
  };

  backToRegistrationStart = (): void => {
    this.registrationStage.changeValue(RegistrationStages.REGISTRATION_START);
  };

  handleTgAuthResponse = async (data: TgAuthFuncData): Promise<void> => {
    this.rootStore.appNotificationsStore.closeBanner(BANNER_ID);

    if (!data) {
      this.requestTgAuthStage = LoadingStage.ERROR;
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.error,
        title: (t) =>
          t('AuthStore.notifications.authError', {
            ns: 'stores',
            channelName: channelKindEntities[ChannelKind.TELEGRAM].title
          })
      });

      return;
    }

    const response = await this.rootStore.networkStore.api<
      {},
      OAuthTgErrors,
      {
        manager_id: string;
        codes: OAuthCommonError[];
      }
    >(apiUrls.OAUTH_TG_LOGIN_REDIRECT, {
      method: 'POST',
      data,
      errorsMap: mapOAuthTgErrorsToMessage,
      showExpectedError: false
    });

    if (response.isError) {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.ERROR;
      });

      if (response.data?.data.codes && response.data?.data.manager_id) {
        this.rootStore.routerStore.push(
          getUrlFromPathAndParamsObject(urls.AUTH.create(AuthStage.ERROR), {
            manager_id: response.data.data.manager_id,
            error: response.data.data.codes
          })
        );
      }
    } else {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.SUCCESS;
      });
      this.rootStore.authFlowStore.authorize();
    }
  };

  requestTgAuth = async (): Promise<void> => {
    if (!this.oauthInfo.value || this.oauthInfo.value.tgBotId === null) {
      return;
    }

    this.requestTgAuthStage = LoadingStage.LOADING;

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.authClickTgAuth);

    try {
      window.Telegram.Login.auth(
        {
          bot_id: this.oauthInfo.value.tgBotId,
          request_access: 'write',
          lang: 'ru'
        },
        this.handleTgAuthResponse
      );
      this.rootStore.appNotificationsStore.openBanner({
        id: BANNER_ID,
        type: AppNotificationType.warning,
        title: (t) =>
          t('AuthStore.notifications.windows', {
            ns: 'stores',
            channelName: channelKindEntities[ChannelKind.TELEGRAM].title
          })
      });
    } catch (e) {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.ERROR;
      });
      // eslint-disable-next-line no-console
      console.log('requestTgAuth error', e);
    }
  };

  validateBeforeRegister = (): boolean => {
    // валидируем поля названия и домена кабинета на фронте
    const isCabinetError =
      this.cabinetCreationStore.cabinetEditModel.validate();

    if (isCabinetError) {
      return true;
    }

    // валидируем имя, email и пароль
    if (!this.registered.value) {
      this.registerEditModel.validate();

      if (this.registerEditModel.isError) {
        return true;
      }
    }

    return false;
  };

  async login(): Promise<void> {
    const isEmailError = this.loginEditModel.email.validate();
    const isPasswordError = this.loginEditModel.password.validate();
    const isTokenError = this.loginCaptcha.failed;

    if (isEmailError || isPasswordError || isTokenError) {
      return;
    }

    const response = await this.rootStore.userStore.login({
      email: this.loginEditModel.email.value,
      password: this.loginEditModel.password.value,
      captcha_token: this.loginCaptcha.token.value
    });

    this.loginCaptcha.reset();

    if (response.isError && response.data) {
      if (Object.values(LoginErrors).includes(response.data as LoginErrors)) {
        const errorText = mapLoginErrorToMessage(response.data as LoginErrors);

        if (errorText) {
          this.loginEditModel.email.setError(response.data); // Не будем выводить в форме
          this.loginEditModel.password.setError(errorText);
        }
      }

      if (
        Object.values(BaseAuthLoginErrors).includes(
          response.data as BaseAuthLoginErrors
        )
      ) {
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.error,
          title: mapBaseLoginAuthErrors(response.data as BaseAuthLoginErrors)
        });
      }

      return;
    }

    this.rootStore.authFlowStore.redirectToAvailableCabinetFromAuthDomain();
  }

  /** Начинаем регистрацию и создание кабинета
   */
  startRegister = async (): Promise<void> => {
    const validationError = this.validateBeforeRegister();

    if (validationError || this.registerAndCabinetCreationStage.isLoading) {
      return;
    }

    this.registerAndCabinetCreationStage.loading();

    // если введено поле домена, проверяем домен на беке, если нет - сразу переходим к регистрации
    if (this.cabinetCreationStore.cabinetEditModel.domain.value.length) {
      const response = await this.checkDomain();
      if (response.isError) {
        this.registerAndCabinetCreationStage.error();
        return;
      }
    }

    this.registerAndCreateCabinet();
  };

  private checkDomain = async (): Promise<BaseResponse> => {
    if (this.checkingDomainStage.isLoading) {
      return { isError: true };
    }

    this.checkingDomainStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.CABINETS_CHECK_DOMAIN_NAME,
      {
        method: 'POST',
        data: {
          domain: this.cabinetCreationStore.cabinetEditModel.domain.value
        },
        errorsMap: mapDomainCheckingErrorToMessage,
        showExpectedError: false
      }
    );

    if (!response.isError) {
      this.checkingDomainStage.success();

      return { isError: false };
    }

    this.checkingDomainStage.error();

    if (response.data) {
      if (Object.values(DomainCheckingError).includes(response.data.code)) {
        if (
          response.data.code === DomainCheckingError.domain_taken &&
          !this.cabinetCreationStore.cabinetEditModel.isNameEmpty
        ) {
          this.cabinetCreationStore.showedGenerationButton.changeValue(true);
          this.cabinetCreationStore.cabinetEditModel.domain.setError((t) =>
            t('cabinet.errors.checkingDomain.domain_taken_with_instruction', {
              ns: 'entities'
            })
          );
          return { isError: true };
        }

        const errorText = mapDomainCheckingErrorToMessage(response.data.code);

        if (errorText) {
          this.cabinetCreationStore.cabinetEditModel.domain.setError(errorText);
          return { isError: true };
        }
      }
    }
    this.cabinetCreationStore.cabinetEditModel.domain.setError((t) =>
      t('cabinet.errors.checkingDomain.unknown_error', {
        ns: 'entities'
      })
    );
    return { isError: true };
  };

  private registerAndCreateCabinet = async () => {
    if (!this.registered.value) {
      // выполнить проверку капчей, если капча выполнится успешно, выполнится реакция и продолжится регистрация this.continueRegister
      this.rootStore.smartCaptcha.executeWithUrl(
        apiUrls.AUTH_REGISTER,
        this.id
      );
    } else {
      const response = await this.cabinetCreationStore.createInitialData();
      if (response.isError) {
        this.registerAndCabinetCreationStage.error();
      } else {
        this.registerAndCabinetCreationStage.success();
      }
    }
  };

  /** Выполнится, если капча для register пройдена. Вызов registerUser. Если успешно переходим к созданию кабинета
   */
  private continueRegister = async (): Promise<void> => {
    const registerUserResponse = await this.registerUser();

    if (registerUserResponse.isError) {
      this.registerAndCabinetCreationStage.error();

      return;
    }

    const cabinetCreationResponse =
      await this.cabinetCreationStore.createInitialData();

    if (cabinetCreationResponse.isError) {
      this.registerAndCabinetCreationStage.error();
    } else {
      this.registerAndCabinetCreationStage.success();
    }
  };

  private registerUser = async (): Promise<BaseResponse> => {
    this.registerStage.loading();

    const response = await this.rootStore.userStore.register({
      captcha_token: this.rootStore.smartCaptcha.token.value,
      ...this.registerEditModel.toJson()
    });

    this.rootStore.smartCaptcha.reset();

    if (response.isError) {
      if (response.data === RegistrationErrors.EMAIL_TAKEN) {
        this.registerEditModel.emailTakenError.changeValue(
          mapRegistrationErrorToMessage(response.data)
        );
        this.backToRegistrationStart();
        this.rootStore.appNotificationsStore.open({
          title: mapRegistrationErrorToMessage(response.data),
          timeout: 20000,
          type: AppNotificationType.error,
          button: {
            type: ComponentType.button,
            size: ButtonSize.s,
            onClick: this.changeStageToLogin,
            children: (t) =>
              t('AuthStore.notifications.goToLogin', { ns: 'stores' })
          }
        });
      }

      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.authRegistrationError,
        {
          registration_error: response.data
        }
      );
      this.registerStage.error();

      return { isError: true };
    }

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.authRegistrationOk);
    this.registered.changeValue(true);
    this.registerStage.success();
    return { isError: false };
  };

  registerReset(): void {
    this.registerEditModel.reset();
    this.cabinetCreationStore.cabinetEditModel.reset();
    this.registrationStage.changeValue(RegistrationStages.REGISTRATION_START);
  }

  unsubscribeAll(): void {
    super.unsubscribeAll();
    this.cabinetCreationStore.unsubscribeAll();
  }

  subscribe = (): void => {
    this.cabinetCreationStore.subscribe();

    this.addReaction({
      key: 'registerCaptchaToken',
      reaction: reaction(
        () => this.rootStore.smartCaptcha.token.value,
        async () => {
          if (
            this.rootStore.smartCaptcha.wasPassed(
              apiUrls.AUTH_REGISTER,
              this.id
            )
          ) {
            this.continueRegister();
          }
        }
      )
    });

    this.addReaction({
      key: 'captchaClosedByUser',
      reaction: reaction(
        () => this.rootStore.smartCaptcha.closedByUser.value,
        () => {
          if (
            this.registerAndCabinetCreationStage.isLoading &&
            this.rootStore.smartCaptcha.closedByUser.value
          ) {
            this.registerAndCabinetCreationStage.error();
          }
        }
      )
    });
  };
}
