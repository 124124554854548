import { BaseResponseCode } from 'shared/entities/network';

import { ChannelKind } from '../channels';
import { TranslationString } from '../localization';

export type UserServer = {
  name: string | null;
  email: string;
  confirmed: boolean;
  _id: string;
};

export type UserTokensServer = Partial<
  Record<ChannelKind.VK | ChannelKind.FB | ChannelKind.IG, boolean>
>;

export type ManagerExternalServer = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  username: string | null;
  link: string | null;
};

export type ManagerExternalsServer = Partial<
  Record<ChannelKind, ManagerExternalServer>
>;

export type UserExtraServer = {
  registered_via_elama?: boolean;
};

export type UserAuthServer<P = Record<string, any>> = {
  manager: UserServer & {
    extra: UserExtraServer;
    external_ids: ManagerExternalsServer;
  };
  tokens: UserTokensServer;
  manager_externals: ManagerExternalsServer;
} & P;

export enum UserAcceptInvitationQueryServer {
  token = 'token',
  email = 'email'
}

export type UserAcceptInvitationServerType = {
  token: string;
  password?: string;
  name?: string;
};

export enum LoginErrors {
  CAPTCHA_ERROR = 'captcha_check_failed',
  INVALID_EMAIL_OR_PASSWORD = 'invalid_email_or_password'
}

export const mapLoginErrorToMessage =
  (code: LoginErrors): TranslationString =>
  (t) =>
    t(`user.loginErrors.${code}`, {
      ns: 'entities'
    });

export enum RegistrationErrors {
  EMAIL_TAKEN = 'email_taken'
}

export enum OAuthTgErrors {
  NOT_ENOUGH_DATA = 'not_enough_data'
}

export const mapOAuthTgErrorsToMessage =
  (code: OAuthTgErrors): TranslationString =>
  (t) =>
    t(`user.oAuthTgErrors.${code}`, {
      ns: 'entities'
    });

export const mapRegistrationErrorToMessage =
  (code: RegistrationErrors): TranslationString =>
  (t) =>
    t(`user.registrationErrors.${code}`, {
      ns: 'entities'
    });

export enum AuthErrors {
  NOT_AUTHORIZED = 'not_authorized'
}

export const mapAuthErrorToMessage =
  (code: AuthErrors): TranslationString =>
  (t) =>
    t(`user.authErrors.${code}`, {
      ns: 'entities'
    });

export enum BaseAuthLoginErrors {
  SHOULD_USE_OAUTH_VK = 'should_use_oauth_vk',
  SHOULD_USE_OAUTH_GOOGLE = 'should_use_oauth_google',
  SHOULD_USE_USUAL_LOGIN = 'should_use_usual_login',
  SHOULD_USE_OAUTH_TG = 'should_use_oauth_tg',
  SHOULD_USE_OAUTH_ELAMA = 'should_use_oauth_elama',
  GOOGLE_INVALID_DATA = 'google_invalid_data'
}

export const mapBaseLoginAuthErrors =
  (code: BaseAuthLoginErrors): TranslationString =>
  (t) =>
    t(`user.oauthErrors.${code}`, {
      ns: 'entities'
    });

export enum OAuthCommonError {
  NEED_EMAIL = 'need_email',
  NEED_NAME = 'need_name'
}

export const mapUpdatingOauthEmailToMessage =
  (code: BaseResponseCode.conflict): TranslationString =>
  (t) =>
    t(`user.updatingOauthManagerErrors.${code}`, {
      ns: 'entities'
    });

export enum AddUserErrorCode {
  notFound = 'not_found'
}

export const mapAddUserErrorCodeToMessage =
  (code: AddUserErrorCode): TranslationString =>
  (t) =>
    t(`user.addingUserErrors.${code}`, {
      ns: 'entities'
    });

export enum EmailUnsubscribeQueryParam {
  token = 'token',
  email = 'email'
}

export enum EmailUnsubscribeError {
  TOKEN_EXPIRES = 'expired_token',
  INVALID_TOKEN = 'invalid_token',
  EXTERNAL_SERVICE_ERROR = 'external_service_error'
}

export const mapEmailUnsubscribeErrorToMessage =
  (code: EmailUnsubscribeError): TranslationString =>
  (t) =>
    t(`user.emailUnsubscribeErrors.${code}`, {
      ns: 'entities'
    });

export type OauthInfoServer = {
  urls: {
    elama: string;
    google: string;
    vk: string;
  };
  tg_bot_id: string;
};
