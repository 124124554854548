import { observer } from 'mobx-react';
import * as React from 'react';

import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink/ExternalLink';
import Typography from 'shared/newComponents/Typography';
import { useLocal } from 'shared/entities/common/hooks';
import { JivoCreationStore } from 'stores/JivoCreationStore';
import { useRootStore } from 'stores/index';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import {
  LoaderSpinnerColor,
  LoaderSpinnerSize
} from 'shared/newEntities/components/LoaderSpinner';
import { Input } from 'shared/newComponents/form';
import { documentationLinks, JIVO_LINK } from 'shared/entities/app';
import { ButtonSize, ButtonStyle } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { Button } from 'shared/newComponents/buttons';
import { noop } from 'shared/entities/common/utils';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { channelKindEntities } from 'shared/entities/channels';
import TransComp from 'shared/components/TransComp';
import { AnalyticsEvent } from 'shared/entities/analytics';

import BannedChannelText from '../BannedChannelText';

import './Jivo.modules.scss';

const Jivo: React.FC = () => {
  const rootStore = useRootStore();
  const { store: modalStore } = useChannelsModalStore();
  const store = useLocal(() => new JivoCreationStore(rootStore));

  const connectedChannel =
    modalStore.connectWithJivoSelectedChannelKind.value &&
    channelKindEntities[modalStore.connectWithJivoSelectedChannelKind.value];

  React.useEffect(() => {
    store.getJivoCreationInfo();
  }, []);

  const handleCreate = React.useCallback(async () => {
    const { isError } = await store.create();
    if (!isError) {
      if (modalStore.connectWithJivoSelectedChannelKind.value) {
        rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.channelCreationViaJivoOk,
          {
            created_channel_kind_via_jivo:
              modalStore.connectWithJivoSelectedChannelKind.value
          }
        );
      }
      modalStore.unSelectChannelKind();
    }
  }, [modalStore.connectWithJivoSelectedChannelKind.value]);

  return (
    <div styleName="jivo">
      {store.getJivoCreationInfoStage.isLoading && (
        <div styleName="jivo__loader">
          <LoaderSpinner
            size={LoaderSpinnerSize.md}
            color={LoaderSpinnerColor.blue}
          />
        </div>
      )}
      {!store.getJivoCreationInfoStage.isLoading &&
        store.webhookToken &&
        store.url.value && (
          <>
            <div styleName="jivo__top">
              <Typography
                fontType={TypographyType.text}
                fontSize={TypographySize.s}
                styleName="jivo__top-instruction"
              >
                {connectedChannel && (
                  <div styleName="jivo__top-instruction-auth">
                    <TypedTransComponent
                      ns="newContainers"
                      i18nKey="ChannelsModal.ChannelForm.Jivo.instructionForConnectedChannel"
                    >
                      1. Авторизуйтесь или зарегистрируйтесь на&nbsp;
                      <ExternalLink
                        fontType={TypographyType.text}
                        fontSize={TypographySize.s}
                        fontColor={TypographyColor.link}
                        underline
                        href={JIVO_LINK}
                      >
                        {JIVO_LINK}
                      </ExternalLink>
                      . В&nbsp;разделе «Управление» → «Каналы связи» добавьте
                      нужный канал.
                      <br />
                    </TypedTransComponent>
                  </div>
                )}
                {!connectedChannel ? '1. ' : '2. '}
                <TransComp>
                  {(t) =>
                    t('ChannelsModal.ChannelForm.Jivo.instruction.copyToken', {
                      ns: 'newContainers'
                    })
                  }
                </TransComp>
                <Input
                  styleName="jivo__item"
                  label={(t) =>
                    t('ChannelsModal.ChannelForm.Jivo.token', {
                      ns: 'newContainers'
                    })
                  }
                  value={store.webhookToken}
                  onChange={noop}
                  withCopying
                  fontSize="s"
                />
                <div styleName="jivo__top-instruction-item">
                  {!connectedChannel ? '2. ' : '3. '}
                  <TypedTransComponent
                    ns="newContainers"
                    i18nKey="ChannelsModal.ChannelForm.Jivo.instruction.goToLink"
                  >
                    Перейдите по{' '}
                    <ExternalLink
                      fontType={TypographyType.text}
                      fontSize={TypographySize.s}
                      fontColor={TypographyColor.link}
                      underline
                      href={store.url.value}
                    >
                      сылке
                    </ExternalLink>
                    на страницу создания интеграции с Jivo. Укажите там
                    информацию о боте, выберите каналы, в которых интеграция
                    будет работать и нажмите «Сохранить».
                  </TypedTransComponent>
                </div>
                <div styleName="jivo__top-instruction-item">
                  {!connectedChannel ? '3. ' : '4. '}
                  <TransComp>
                    {(t) =>
                      t(
                        'ChannelsModal.ChannelForm.Jivo.instruction.clickCreate',
                        {
                          ns: 'newContainers'
                        }
                      )
                    }
                  </TransComp>
                </div>
                {!connectedChannel && (
                  <div styleName="jivo__top-instruction-item">
                    <TransComp>
                      {(t) =>
                        t('ChannelsModal.ChannelForm.Jivo.instructionForAuth', {
                          ns: 'newContainers'
                        })
                      }
                    </TransComp>
                  </div>
                )}
              </Typography>
            </div>
            <div styleName="jivo__bottom">
              <Button
                styleName="jivo__bottom-documentation"
                size={ButtonSize.sm}
                withoutPadding
                style={ButtonStyle.transparent}
                type={ComponentType.a}
                rel="noreferrer noopener"
                target="_blank"
                href={documentationLinks.createJivoChannel}
              >
                {(t) =>
                  t('ChannelsModal.ChannelForm.Jivo.instructionButton', {
                    ns: 'newContainers'
                  })
                }
              </Button>
              <Button
                styleName="jivo__bottom-button"
                size={ButtonSize.lg}
                type={ComponentType.button}
                onClick={handleCreate}
                loadingStage={store.creationStage.value}
                fullWidth
              >
                {(t) =>
                  t('ChannelsModal.ChannelForm.button', {
                    ns: 'newContainers'
                  })
                }
              </Button>
            </div>
          </>
        )}
      {connectedChannel?.banned && (
        <BannedChannelText styleName="jivo__banned" />
      )}
    </div>
  );
};

export default observer(Jivo);
