import { computed, makeObservable } from 'mobx';

import { CabinetCategory, CabinetCreateServer } from 'shared/entities/cabinet';
import {
  validateDomainField,
  validateDomainLength,
  validateMaxDomainLength,
  validateTextFieldMaxLength
} from 'shared/entities/validator';

import { FieldModel } from '../form';

export default class CabinetEditModel {
  readonly name: FieldModel<string> = new FieldModel<string>('', [
    validateTextFieldMaxLength
  ]);

  readonly domain: FieldModel<string> = new FieldModel<string>('', [
    validateMaxDomainLength,
    validateDomainLength,
    validateDomainField
  ]);

  constructor() {
    makeObservable(this, {
      isError: computed
    });
  }

  get isNameEmpty(): boolean {
    return !this.name.value.length;
  }

  get isError(): boolean {
    return this.name.isError || this.domain.isError;
  }

  toJson(): CabinetCreateServer {
    return {
      name: this.name.value || null,
      category: CabinetCategory.other,
      domain: this.domain.value || undefined
    };
  }

  changeDomain = (value: string): void => {
    this.domain.changeValue(value.toLowerCase());
  };

  validateDomain = (): void => {
    if (this.domain.value.length) {
      this.domain.validate();
    }
  };

  validate(): boolean {
    this.name.validate();

    if (this.domain.value.length) {
      this.validateDomain();
    }
    return this.isError;
  }

  reset(): void {
    this.name.reset();
    this.domain.reset();
    this.resetErrors();
  }

  resetErrors(): void {
    this.name.resetError();
    this.domain.resetError();
  }
}
