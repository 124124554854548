import { computed, makeObservable } from 'mobx';
import * as React from 'react';

import { apiUrls } from 'shared/entities/domain';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  ChannelCommonServer,
  CreateChannelError,
  CreateJivoError,
  mapCreateJivoChannelErrorToMessage
} from 'shared/entities/channels';
import { CommonChannelModel } from 'shared/models/channel';
import { AppNotificationType } from 'shared/entities/appNotifications';
import TypedTransComponent from 'shared/components/TypedTransComponent';
export class JivoCreationStore {
  readonly url: FieldModel<string | null> = new FieldModel<string | null>(null);
  private readonly token: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);

  readonly getJivoCreationInfoStage: LoadingStageModel =
    new LoadingStageModel();
  readonly creationStage: LoadingStageModel = new LoadingStageModel();

  readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      webhookToken: computed
    });
  }

  get webhookToken(): string | null {
    return this.token.value;
  }

  async getJivoCreationInfo(): Promise<BaseResponse> {
    if (this.getJivoCreationInfoStage.isLoading) {
      return {
        isError: true
      };
    }

    this.getJivoCreationInfoStage.loading();

    const response = await this.rootStore.networkStore.api<{
      url: string;
      channel_id: string;
    }>(apiUrls.CHANNELS_GET_JV_CREATION_INFO);

    if (!response.isError) {
      this.url.changeValue(response.data.url);
      this.token.changeValue(response.data.channel_id);
      this.getJivoCreationInfoStage.success();
    } else {
      this.getJivoCreationInfoStage.error();
    }

    return {
      isError: response.isError
    };
  }

  create = async (): Promise<BaseResponse> => {
    if (this.creationStage.isLoading || !this.token.value) {
      return {
        isError: true
      };
    }

    this.creationStage.loading();

    const response = await this.rootStore.networkStore.api<
      {
        channel: ChannelCommonServer;
      },
      CreateChannelError.channelAlreadyExist | CreateJivoError
    >(apiUrls.CHANNELS_ADD_JV, {
      method: 'POST',
      data: {
        channel_id: this.token.value
      },
      errorsMap: mapCreateJivoChannelErrorToMessage,
      expectedErrorCodes: [CreateJivoError.channelNotLinkedToJv],
      showExpectedError: false
    });

    if (!response.isError) {
      const channel = CommonChannelModel.fromJson(
        response.data.channel,
        this.rootStore
      );
      this.rootStore.channelsStore.addCreatedChannel(channel);
      this.creationStage.success();

      return {
        isError: false
      };
    } else {
      if (response.data?.code) {
        if (response.data.code === CreateJivoError.channelNotLinkedToJv) {
          this.rootStore.appNotificationsStore.open({
            type: AppNotificationType.error,
            title: (
              <TypedTransComponent
                ns="stores"
                i18nKey="AppNotificationsStore.JivoCreationStore.error.channel_not_linked_to_jv"
              >
                Создание канала не завершено на странице Jivo. Пожалуйста,
                вернитесь, проверьте, корректно ли введены данные, и закончите
                настройку. Если у вас возникли трудности,{' '}
                <span
                  className="link-new link-new_inherit"
                  onClick={this.rootStore.supportPopupStore.openChat}
                >
                  обратитесь в нашу поддержку.
                </span>
              </TypedTransComponent>
            )
          });
        } else {
          this.url.setError(
            mapCreateJivoChannelErrorToMessage(response.data.code)
          );
        }
      }
      this.creationStage.error();

      return {
        isError: true
      };
    }
  };
}
