import { INewList } from 'shared/entities/list';
import { ProductOptionValuesStrategies } from 'shared/models/shopback/productOptions/productOptionValues';
import { FieldModel } from 'shared/models/form';
import { ILoadingStageModel } from 'shared/entities/loadingStage';
import { IRootStore } from 'shared/entities/store/rootStore';
import { HexString } from 'shared/entities/color';

import { IField } from '../../form';
import { SelectorEntities, SelectorOptions } from '../../components/Selector';
import { TFunctionType } from '../../localization';

import { ProductOptionType } from './server';

export const MAX_PRODUCT_OPTIONS_AMOUNT = 10;

export type ColorProductOptionValue = {
  colorName: string; // Название цвета
  colorCode: HexString; // HEX-код цвета
};

export type ColorProductOptionField = {
  colorName: IField<ColorProductOptionValue['colorName']>;
  colorCode: IField<ColorProductOptionValue['colorCode']>;
};

export type OtherProductOptionValue = string;

export type OtherProductOptionField = IField;

export const productOptionTypesOrder: ProductOptionType[] = [
  ProductOptionType.color,
  ProductOptionType.other
];

export const productOptionSelectorEntities: SelectorEntities<ProductOptionType> =
  productOptionTypesOrder.reduce(
    (acc, productOptionType): SelectorEntities<ProductOptionType> => ({
      ...acc,
      [productOptionType]: {
        id: productOptionType,
        title: (t: TFunctionType): string =>
          t(`shopback.options.optionTypeName.${productOptionType}`, {
            ns: 'entities'
          })
      }
    }),
    {} as SelectorEntities<ProductOptionType>
  );

export const typeOptionSelectorOptions: SelectorOptions = {
  keys: productOptionTypesOrder,
  entities: productOptionSelectorEntities
};

export enum ProductOptionModelType {
  // Характеристика, у которой можно менять всё и которую можно изменять, удалять
  definable = 'definable',

  // Характеристика, у которой можно менять только значение
  editable = 'editable'
}

export interface IOptionValueModel {
  isError: boolean;
  validate(): boolean;
}

/**
 * Интерфейс модели характеристики товара.
 * modifierId имеется всегда, так как, если модель
 * ещё не сохранена на сервере, то это DefinableProductOptionModel
 * @see DefinableProductOptionModel
 */
export interface IProductOptionModel {
  readonly title: string;
  readonly modifierId: number;
  readonly optionId: number;
  readonly type: ProductOptionType;
  readonly value: string;
  readonly extraValue: string | null;
}

export type ProductOptionModelParams = {
  title: string;
  modifierId: number;
  optionId: number;
  type: ProductOptionType;
  value: string;
  extraValue: string | null;
};

export type DefinableProductOptionDefaultCreatingParams = {
  optionType?: ProductOptionType;
  title?: string;
};

export type DefinableProductOptionEditingParams = {
  title: FieldModel<string>;
  annotatedValueModel: ProductOptionValuesStrategies;
};

// Характеристика товара в списке характеристик
export type IBaseProductOption = {
  id: number;
  type: ProductOptionType;
  name: FieldModel<string>;
};

export interface IProductOptionModifierModel {
  readonly clientId: string;
  readonly id: number | null;
  readonly optionId: number;
  readonly annotatedValue: ProductOptionValuesStrategies;
  readonly rootStore: IRootStore;
  readonly shopId: number;
  readonly value: string;
  readonly extraValue: string | null;
}

export interface IShopbackProductOptionsStore {
  readonly selectedOptionId: IField<number | null>;
  readonly options: INewList<IBaseProductOption, number>;
}

export interface IProductOptionModifiersStore {
  readonly shopId: number;
  readonly optionData: IField<ProductOptionData | null>;
  readonly modifiers: INewList<IProductOptionModifierModel, number | string>;
  readonly addingModifierStage: ILoadingStageModel;
  readonly selectorOptions: IField<SelectorOptions>;

  changeOptionData: (value: ProductOptionData | null) => void;
  addModifier: (params: {
    value: string;
    extra_value?: string;
  }) => Promise<BaseResponse<IProductOptionModifierModel>>;
  fillSelectorOptions: () => void;
}

export type ProductOptionData = {
  id: number;
  type: ProductOptionType;
};
