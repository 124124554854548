import * as React from 'react';

import {
  BaseModalProps,
  ModalSize
} from 'shared/newEntities/components/modals';
import {
  TranslationNode,
  TranslationString
} from 'shared/entities/localization';
import { ButtonProps } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';

import BaseFormModal from '../BaseFormModal';

import './FormModal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    title?: TranslationString;
    ok: ButtonProps;
    withoutCancelButton?: boolean;
    onEnter?: () => void;
    className?: string;
    rightFooter?: boolean;
    footnotes?: TranslationNode;
    cancelButtonTitle?: TranslationString;
    withHeader?: boolean;
  }
>;

const FormModal: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  withoutCancelButton = false,
  className,
  rightFooter,
  footnotes,
  cancelButtonTitle,
  ...rest
}: Props) => {
  return (
    <BaseFormModal
      {...rest}
      size={ModalSize.xs}
      fixHeight={false}
      className={className}
      opened={rest.opened}
      ok={ok}
      cancel={
        withoutCancelButton
          ? undefined
          : {
              type: ComponentType.button,
              onClick: rest.onClose,
              children: cancelButtonTitle
            }
      }
      onEnter={onEnter}
      rightFooter={rightFooter}
      footnotes={footnotes}
    >
      {children}
    </BaseFormModal>
  );
};

export default FormModal;
