import { IField } from 'shared/entities/form';

import { ChannelKind } from '../channels';

export interface ITmpUser {
  email: IField;
  password: IField;
}

export interface IUser {
  name: string | null;
  email: string;
  confirmed: boolean;
  id: string;
}

export type UserTokens = Record<
  ChannelKind.VK | ChannelKind.FB | ChannelKind.IG,
  boolean
>;

export interface IUserExtra {
  registeredViaElama: boolean;
}

export type UserUploadReport = {
  errors: string[];
  warnings: string[];
  total_invalid_users: number;
  is_short_version: boolean;
  total_users: number;
  total_added_users: number;
  total_duplicated_users: number;
};

export interface IManagerExternal {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  link: string;
}

export type ManagerExternals = Partial<Record<ChannelKind, IManagerExternal>>;

export interface IOauthInfo {
  urls: {
    elama: string | null;
    google: string | null;
    vk: string | null;
  };
  tgBotId: string | null;
}

export enum RegistrationStages {
  REGISTRATION_START = 'registration_start',
  CABINET_CREATION = 'cabinet_creation'
}

export const registrationStagesOrder = [
  RegistrationStages.REGISTRATION_START,
  RegistrationStages.CABINET_CREATION
];
