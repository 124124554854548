export enum LoaderSpinnerSize {
  sm = 'sm',
  md = 'md',
  m = 'm'
}

export enum LoaderSpinnerColor {
  blue = 'blue',
  white = 'white'
}
