import { computed, makeObservable } from 'mobx';

import { ISmartCaptcha } from 'shared/entities/smartCaptcha';

import { FieldModel } from '../form';
export default class SmartCaptchaModel implements ISmartCaptcha {
  readonly token: FieldModel<string> = new FieldModel<string>('');
  readonly visible: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly resetCaptcha: FieldModel<number> = new FieldModel<number>(0);
  readonly closedByUser: FieldModel<boolean> = new FieldModel<boolean>(false);
  // данные, необходимые для того, чтобы понять для какого стора и запроса выполнилась капча
  readonly requestDataNeedCaptchaToken: FieldModel<{
    id: string;
    url: string;
  } | null> = new FieldModel<{ id: string; url: string } | null>(null);

  constructor() {
    makeObservable(this, {
      failed: computed
    });
  }

  get failed(): boolean {
    return !this.token.value.length;
  }

  execute = (): void => {
    if (this.closedByUser.value) {
      this.closedByUser.changeValue(false);
    }

    this.visible.changeValue(true);
  };

  /**
   * Вызывает проверку капчей и запоминает информацию о том, откуда вызвали проверку и
   * @param url url запроса, требующего капчу, id: id стора, откуда вызвали проверку
   */
  executeWithUrl = (url: string, id: string): void => {
    this.requestDataNeedCaptchaToken.changeValue({ url, id });
    // выполнить проверку капчей
    this.execute();
  };

  wasPassed = (url: string, id: string): boolean => {
    return (
      !this.failed &&
      this.requestDataNeedCaptchaToken.value?.id === id &&
      this.requestDataNeedCaptchaToken.value?.url === url
    );
  };

  reset = (): void => {
    this.resetCaptcha.changeValue(this.resetCaptcha.value + 1);
    this.visible.changeValue(false);
    this.requestDataNeedCaptchaToken.changeValue(null);
    this.token.changeValue('');
  };
}
