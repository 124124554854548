import { TranslationString } from 'shared/entities/localization';
import { BaseResponseCode } from 'shared/entities/network';

import { SelectorEntities } from '../components/Selector';
import { CabinetManagerRoleType } from '../manager';
import { Permission } from '../permissions';

export enum CabinetCategory {
  agency = 'agency',
  marketing = 'marketing',
  business = 'business',
  education = 'education',
  brand = 'brand',
  media = 'media',
  entertainment = 'entertainment',
  other = 'other'
}

export const cabinetCategoryEntities: SelectorEntities<
  CabinetCategory,
  TranslationString
> = {
  [CabinetCategory.agency]: {
    id: CabinetCategory.agency,
    title: (t) => t('cabinet.category.agency', { ns: 'entities' })
  },
  [CabinetCategory.marketing]: {
    id: CabinetCategory.marketing,
    title: (t) => t('cabinet.category.marketing', { ns: 'entities' })
  },
  [CabinetCategory.business]: {
    id: CabinetCategory.business,
    title: (t) => t('cabinet.category.business', { ns: 'entities' })
  },
  [CabinetCategory.education]: {
    id: CabinetCategory.education,
    title: (t) => t('cabinet.category.education', { ns: 'entities' })
  },
  [CabinetCategory.brand]: {
    id: CabinetCategory.brand,
    title: (t) => t('cabinet.category.brand', { ns: 'entities' })
  },
  [CabinetCategory.media]: {
    id: CabinetCategory.media,
    title: (t) => t('cabinet.category.media', { ns: 'entities' })
  },
  [CabinetCategory.entertainment]: {
    id: CabinetCategory.entertainment,
    title: (t) => t('cabinet.category.entertainment', { ns: 'entities' })
  },
  [CabinetCategory.other]: {
    id: CabinetCategory.other,
    title: (t) => t('cabinet.category.other', { ns: 'entities' })
  }
};

export const cabinetCategoryOrder: CabinetCategory[] = [
  CabinetCategory.agency,
  CabinetCategory.entertainment,
  CabinetCategory.business,
  CabinetCategory.marketing,
  CabinetCategory.education,
  CabinetCategory.brand,
  CabinetCategory.media,
  CabinetCategory.other
];

export enum CabinetSplit {
  initial = 'initial',
  increasedMinimum = 'increased_minimum',
  increasedMinimumPaywall = 'increased_minimum_paywall',
  increasedAll = 'increased_all',
  corporate = 'corporate',
  bigPrice0125 = 'big_price_01_25'
}

export type CabinetBankRequisitesServer = {
  // Расчётный счёт
  account: string;
  // БИК
  bic: string;
  // Название
  name: string;
  // Адрес
  address: string;
  // Корреспондентский счёт
  corr_account: string;
};

export enum LegalForm {
  ooo = 'ООО',
  ao = 'АО',
  pao = 'ПАО',
  zao = 'ЗАО',
  oao = 'ОАО',
  ip = 'ИП'
}

export enum OtherLegalForm {
  other = 'Другое'
}

export const legalFormOrder: Array<LegalForm | OtherLegalForm.other> = [
  LegalForm.ooo,
  LegalForm.ao,
  LegalForm.pao,
  LegalForm.zao,
  LegalForm.oao,
  LegalForm.ip,
  OtherLegalForm.other
];

export type LegalFormDataServer =
  | { legal_form: LegalForm }
  | {
      legal_form: string;
      legal_form_full: string;
    };

export type CabinetRequisitesServer = {
  // Название
  name: string;
  legal_form: LegalForm | string;
  legal_form_full?: string;
  // Лицо принимающее решение
  lpr: string;
  // ОГРН
  ogrn: string;
  // ИНН
  inn: string;
  // КПП
  kpp: string;
  // Юридический адрес
  legal_address: string;
  // Почтовый адрес
  post_address: string;
  // Номер телефона
  phone: string;
  // Банковские реквизиты
  bank: CabinetBankRequisitesServer;
};

export type CabinetBillingServer = {
  balance: number;
  legal_entity_balance?: number;
  trial_until: string;
  split: CabinetSplit;
  had_linked_card?: boolean;
  requisites?: CabinetRequisitesServer;
};

export type CabinetServer = {
  _id: string;
  domain: string;
  name: string;
  permissions: Array<Permission>;
  cabinet_role: CabinetManagerRoleType;
  category: CabinetCategory;
  should_pay: boolean;
  billing: CabinetBillingServer;
};

export type CabinetCreateServer = {
  domain?: string;
  name: string | null;
  category: CabinetCategory;
};

export type CabinetCreationError =
  | BaseResponseCode.conflict
  | DomainCheckingError;

export const mapCabinetCreationErrorToMessage = (
  code: CabinetCreationError
): TranslationString => {
  if (code === BaseResponseCode.conflict) {
    return (t) =>
      t(`cabinet.errors.creation.${code}`, {
        ns: 'entities'
      });
  }
  return mapDomainCheckingErrorToMessage(code);
};

export enum DomainCheckingError {
  domain_taken = 'domain_taken',
  domain_invalid = 'domain_invalid'
}

export const mapDomainCheckingErrorToMessage =
  (code: DomainCheckingError): TranslationString =>
  (t) =>
    t(`cabinet.errors.checkingDomain.${code}`, {
      ns: 'entities'
    });
