import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import {
  MAX_NAME_LENGTH,
  validateEmailField,
  validateIsEmpty,
  validateName,
  validatePassword,
  validateTextFieldMaxLength,
  ValidatorResult
} from 'shared/entities/validator';

export default class UserEditModel {
  readonly email: FieldModel<string> = new FieldModel<string>('', [
    validateIsEmpty,
    validateEmailField,
    (value) => validateTextFieldMaxLength(value, MAX_NAME_LENGTH)
  ]);
  readonly password: FieldModel = new FieldModel<string>('', [
    validateIsEmpty,
    validatePassword
  ]);
  readonly name: FieldModel = new FieldModel<string>('', [
    validateIsEmpty,
    validateName
  ]);

  readonly emailTakenError = new FieldModel<ValidatorResult>(null);

  constructor() {
    makeObservable(this, {
      isError: computed
    });
  }

  get emailError(): ValidatorResult {
    return this.emailTakenError.value || this.email.error;
  }

  get isError(): boolean {
    return !!this.emailError || this.password.isError || this.name.isError;
  }

  reset(): void {
    this.email.reset();
    this.password.reset();
    this.name.reset();
    this.resetErrors();
  }

  resetErrors(): void {
    this.email.resetError();
    this.emailTakenError.changeValue(null);
    this.password.resetError();
    this.name.resetError();
  }

  validate(): void {
    this.email.validate();
    this.password.validate();
    this.name.validate();
  }

  validateEmail = () => {
    if (!this.emailTakenError.value) {
      this.email.validate();
    }
  };

  changeEmail = (value: string) => {
    this.email.changeValue(value);
    this.emailTakenError.changeValue(null);
  };

  toJson(): { email: string; password: string; name: string } {
    return {
      email: this.email.value.trim(),
      password: this.password.value.trim(),
      name: this.name.value.trim()
    };
  }
}
