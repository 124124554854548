import * as React from 'react';
// eslint-disable-next-line import/named
import { throttle } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

const useShowVerticalFade = (
  initialShowFade = false
): {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  showFadeRight: boolean;
  showFadeLeft: boolean;
  handleScroll: () => void;
} => {
  const [showFadeRight, setShowFadeRight] =
    React.useState<boolean>(initialShowFade);
  const [showFadeLeft, setShowFadeLeft] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = React.useCallback(
    throttle(() => {
      if (!ref.current) {
        return;
      }
      const element = ref.current;

      // показываем fade только, если есть скролл и не дошли до конца при скролле
      const hasScroll = element.scrollWidth > element.clientWidth;
      const isRight =
        element.scrollLeft + element.offsetWidth >= element.scrollWidth;

      setShowFadeRight(hasScroll && !isRight);
      setShowFadeLeft(hasScroll && isRight);
    }, 100),
    []
  );

  React.useEffect(() => {
    let observer: ResizeObserver | null = null;

    if (ref.current) {
      observer = new ResizeObserver(handleScroll);

      observer.observe(ref.current);
      handleScroll();
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return { ref, showFadeRight, showFadeLeft, handleScroll };
};

export default useShowVerticalFade;
