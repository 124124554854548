import * as React from 'react';

import { TranslationString } from 'shared/entities/localization';

import { ReferenceProps } from './Reference';

export type BaseModalProps = {
  opened: boolean;
  onClose: () => void;
};

export enum ModalSize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l'
}

export type ContentModalProps = React.PropsWithChildren<
  BaseModalProps & {
    title?: TranslationString | string;
    headerExtraComponent?: React.ReactNode;
    headerRightComponent?: React.ReactNode;
    fixHeight?: boolean;
    size?: ModalSize;
    reference?: ReferenceProps;
    style?: React.CSSProperties;
    withHeader?: boolean;
  }
>;
