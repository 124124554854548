import { generateId } from '../common/utils';

import { BillingServer, PriceServer } from './server';
import { IBilling, IPrice } from './client';

const DAY_PRICE_FOR_PERSONAL_MANAGER = 130;

const getMaxUsersTitleForElement = ({
  index,
  prices
}: {
  index: number;
  prices: PriceServer[];
}): string | null => {
  const price = prices[index];

  if (!price) {
    return null;
  }

  switch (index) {
    // если первый тариф, то "< {макс кол-во подписчиков этого тарифа}"
    case 0: {
      if (price.max_users_count === null) {
        return null;
      }

      return `< ${price.max_users_count.toLocaleString()}`;
    }

    // если последний тариф, то "{макс кол-во подписчиков предыдущего тарифа} - ∞"
    case prices.length - 1: {
      const prevEl = prices[index - 1];

      if (
        price.max_users_count !== null ||
        typeof prevEl.max_users_count !== 'number'
      ) {
        return null;
      }

      return `${prevEl.max_users_count.toLocaleString()} - ∞`;
    }

    // тариф в середине, то "{макс кол-во подписчиков предыдущего тарифа} - {макс кол-во подписчиков этого тарифа}"
    default: {
      const prevPrice = prices[index - 1];

      if (
        prevPrice.max_users_count === null ||
        price.max_users_count === null
      ) {
        return null;
      }

      return `${prevPrice.max_users_count.toLocaleString()} - ${price.max_users_count.toLocaleString()}`;
    }
  }
};

const getAvailableNocodeCloudToolsLimitFromDayPrice = ({
  dayPrice
}: {
  dayPrice: number;
}): {
  allToolsLimit: number;
  advancedToolsLimit: number;
} => {
  if (dayPrice >= 230) {
    return {
      allToolsLimit: 3,
      advancedToolsLimit: 3
    };
  }
  switch (dayPrice) {
    case 25:
    case 33:
      return {
        allToolsLimit: 1,
        advancedToolsLimit: 0
      };
    case 40:
    case 63:
    case 65:
    case 66:
    case 115:
      return {
        allToolsLimit: 2,
        advancedToolsLimit: 0
      };
    case 130:
    case 163:
      return {
        allToolsLimit: 3,
        advancedToolsLimit: 1
      };
    default:
      return {
        allToolsLimit: 2,
        advancedToolsLimit: 0
      };
  }
};

export const normalizePrices = (
  prices: PriceServer[]
): {
  entities: Record<string, IPrice>;
  keys: string[];
} => {
  return prices.reduce<{
    entities: Record<string, IPrice>;
    keys: string[];
  }>(
    (acc, raw, index) => {
      const maxUsersCountTitle = getMaxUsersTitleForElement({
        index,
        prices
      });

      if (maxUsersCountTitle === null) {
        return acc;
      }

      const dayPrice = Math.round(raw.price / 30);

      const normalizedPrice: IPrice = {
        id: generateId(),
        maxUsersCount: raw.max_users_count,
        maxUsersCountTitle,
        price: raw.price,
        dayPrice,
        profitably: raw.is_profitable,
        personalManager: dayPrice >= DAY_PRICE_FOR_PERSONAL_MANAGER,
        ...getAvailableNocodeCloudToolsLimitFromDayPrice({ dayPrice })
      };
      acc.entities[normalizedPrice.id] = normalizedPrice;
      acc.keys.push(normalizedPrice.id);

      return acc;
    },
    {
      entities: {},
      keys: []
    }
  );
};

export const normalizeBilling = (raw: BillingServer): IBilling => ({
  balance: raw.balance,
  trialUntil: new Date(raw.trial_until)
});
