import { TranslationString } from 'shared/entities/localization';
import { BadgeColor } from 'shared/newEntities/components/Badge';

import { TgCommandServer } from '../tgCommand';
import {
  TelegramOriginalIcon,
  TelegramTransparentIcon,
  JivoTransparentIcon,
  JivoOriginalIcon,
  VKOriginalIcon,
  VKTransparentIcon,
  ViberTransparentIcon,
  ViberOriginalIcon,
  WhatsAppOriginalIcon,
  WhatsAppTransparentIcon,
  AvitoOriginalIcon,
  AvitoTransparentIcon,
  InstagramTransparentIcon,
  FacebookTransparentIcon,
  OKOriginalIcon,
  OKTransparentIcon,
  AliExpressOriginalIcon,
  AliExpressTransparentIcon,
  FacebookOriginalIcon,
  InstagramOriginalIcon,
  AvitoFullOriginalIcon,
  AvitoFullTransparentIcon,
  AliExpressFullOriginalIcon,
  AliExpressFullTransparentIcon,
  WazzupOriginalIcon,
  WazzupTransparentIcon,
  WABAOriginalIcon,
  WABATransparentIcon
} from '../../newComponents/icons';
import { IconComponent } from '../components/Icon';

export enum ChannelKind {
  VK = 'vk',
  TELEGRAM = 'tg',
  FB = 'fb',
  IG = 'ig',
  VB = 'vb',
  JIVO = 'jv',
  WHATSAPP = 'whatsapp',
  AVITO = 'avito',
  OK = 'OK',
  ALIEXPRESS = 'aliExpress',
  WZ = 'wz',
  WABA = 'waba',
  TELEGRAM_BOT = 'tgBot',
  TELEGRAM_PERSONAL = 'tgPersonal'
}

export type CommonChannelKind = Exclude<ChannelKind, ChannelKind.TELEGRAM>;

export type OpenedChannelKind =
  | ChannelKind.VB
  | ChannelKind.TELEGRAM
  | ChannelKind.VK
  | ChannelKind.JIVO
  | ChannelKind.WZ;

export const openedChannelKindOrder: OpenedChannelKind[] = [
  ChannelKind.TELEGRAM,
  ChannelKind.VK,
  ChannelKind.WZ,
  ChannelKind.JIVO,
  ChannelKind.VB
];

export const connectViaJivoChannelKindOrder = [
  ChannelKind.TELEGRAM,
  ChannelKind.VK,
  ChannelKind.VB,
  ChannelKind.WHATSAPP,
  ChannelKind.IG,
  ChannelKind.AVITO,
  ChannelKind.FB,
  ChannelKind.OK,
  ChannelKind.ALIEXPRESS
] as const;

export type ConnectedViaJivoChannelKind =
  (typeof connectViaJivoChannelKindOrder)[number];

export const connectViaWazzupChannelKindOrder = [
  ChannelKind.WABA,
  ChannelKind.WHATSAPP,
  ChannelKind.IG,
  ChannelKind.TELEGRAM_PERSONAL,
  ChannelKind.TELEGRAM_BOT,
  ChannelKind.AVITO
] as const;

export type ConnectedViaWazzupChannelKind =
  (typeof connectViaWazzupChannelKindOrder)[number];

export const externalChannelKindOrder = [
  ChannelKind.TELEGRAM,
  ChannelKind.VK,
  ChannelKind.VB,
  ChannelKind.WHATSAPP,
  ChannelKind.IG,
  ChannelKind.AVITO,
  ChannelKind.FB,
  ChannelKind.OK,
  ChannelKind.ALIEXPRESS,
  ChannelKind.WABA,
  ChannelKind.TELEGRAM_PERSONAL
] as const;

export type ExternalChannelKindType = (typeof externalChannelKindOrder)[number];

export enum ChannelTokenStatus {
  ok = 'ok',
  absent = 'absent',
  invalid = 'invalid'
}

export type ChannelKindType = ChannelKind | string;

export enum ChannelTagType {
  personal = 'personal',
  business = 'business',
  bot = 'bot'
}

export type ChannelListItem = {
  id: ChannelKind;
  title: string;
  Icon: IconComponent;
  TransparentIcon: IconComponent;
  FullIcon?: IconComponent;
  FullTransparentIcon?: IconComponent;
  label?: 'new' | 'beta';
  tag?: ChannelTagType;
  banned?: boolean;
  fullTitle?: string;
};

export const channelKindEntities: Record<ChannelKind, ChannelListItem> = {
  [ChannelKind.VK]: {
    id: ChannelKind.VK,
    Icon: VKOriginalIcon,
    title: 'ВКонтакте',
    TransparentIcon: VKTransparentIcon
  },
  [ChannelKind.TELEGRAM]: {
    id: ChannelKind.TELEGRAM,
    Icon: TelegramOriginalIcon,
    title: 'Telegram',
    TransparentIcon: TelegramTransparentIcon
  },
  [ChannelKind.JIVO]: {
    id: ChannelKind.JIVO,
    Icon: JivoOriginalIcon,
    title: 'JivoChat',
    TransparentIcon: JivoTransparentIcon
  },
  [ChannelKind.VB]: {
    id: ChannelKind.VB,
    Icon: ViberOriginalIcon,
    title: 'Viber',
    TransparentIcon: ViberTransparentIcon
  },
  [ChannelKind.WHATSAPP]: {
    id: ChannelKind.WHATSAPP,
    Icon: WhatsAppOriginalIcon,
    title: 'WhatsApp',
    TransparentIcon: WhatsAppTransparentIcon,
    label: 'new'
  },
  [ChannelKind.FB]: {
    id: ChannelKind.FB,
    Icon: FacebookOriginalIcon,
    title: 'Facebook*',
    TransparentIcon: FacebookTransparentIcon,
    label: 'beta',
    banned: true
  },
  [ChannelKind.IG]: {
    id: ChannelKind.IG,
    Icon: InstagramOriginalIcon,
    title: 'Instagram*',
    TransparentIcon: InstagramTransparentIcon,
    label: 'beta',
    banned: true
  },
  [ChannelKind.AVITO]: {
    id: ChannelKind.AVITO,
    title: 'Avito',
    Icon: AvitoOriginalIcon,
    FullIcon: AvitoFullOriginalIcon,
    TransparentIcon: AvitoTransparentIcon,
    FullTransparentIcon: AvitoFullTransparentIcon,
    label: 'beta'
  },
  [ChannelKind.OK]: {
    id: ChannelKind.OK,
    Icon: OKOriginalIcon,
    title: 'Одноклассники',
    TransparentIcon: OKTransparentIcon,
    label: 'beta'
  },
  [ChannelKind.ALIEXPRESS]: {
    id: ChannelKind.ALIEXPRESS,
    title: 'AliExpress',
    Icon: AliExpressOriginalIcon,
    TransparentIcon: AliExpressTransparentIcon,
    FullIcon: AliExpressFullOriginalIcon,
    FullTransparentIcon: AliExpressFullTransparentIcon,
    label: 'beta'
  },
  [ChannelKind.WZ]: {
    id: ChannelKind.WZ,
    title: 'Wazzup24',
    Icon: WazzupOriginalIcon,
    TransparentIcon: WazzupTransparentIcon,
    label: 'new'
  },
  [ChannelKind.WABA]: {
    id: ChannelKind.WABA,
    Icon: WABAOriginalIcon,
    title: 'WABA',
    TransparentIcon: WABATransparentIcon,
    label: 'new',
    fullTitle: 'WhatsApp Business(WABA)'
  },
  [ChannelKind.TELEGRAM_BOT]: {
    id: ChannelKind.TELEGRAM_BOT,
    Icon: TelegramOriginalIcon,
    title: 'Telegram bot',
    TransparentIcon: TelegramTransparentIcon,
    tag: ChannelTagType.bot
  },
  [ChannelKind.TELEGRAM_PERSONAL]: {
    id: ChannelKind.TELEGRAM_PERSONAL,
    Icon: TelegramOriginalIcon,
    title: 'Telegram Personal',
    TransparentIcon: TelegramTransparentIcon
  }
};

export const channelsKindsList: ChannelListItem[] = [
  channelKindEntities[ChannelKind.TELEGRAM],
  channelKindEntities[ChannelKind.VK],
  channelKindEntities[ChannelKind.FB],
  channelKindEntities[ChannelKind.IG],
  channelKindEntities[ChannelKind.VB],
  channelKindEntities[ChannelKind.JIVO]
];

export const channelKindOrder = [
  ChannelKind.TELEGRAM,
  ChannelKind.VK,
  ChannelKind.FB,
  ChannelKind.IG,
  ChannelKind.VB,
  ChannelKind.JIVO,
  ChannelKind.WZ
];

export enum ChannelTokenScope {
  messages = 'messages',
  wall = 'wall'
}

export const mapChannelTokenScopeToTitle: Record<ChannelTokenScope, string> = {
  [ChannelTokenScope.messages]: 'Сообщения',
  [ChannelTokenScope.wall]: 'Комментарии'
};

export const mapChannelTokenScopeToBadgeColor: Record<
  ChannelTokenScope,
  BadgeColor
> = {
  [ChannelTokenScope.messages]: BadgeColor.blue,
  [ChannelTokenScope.wall]: BadgeColor.purple
};

export type ChannelTokenServer = {
  _id: string;
  scopes: ChannelTokenScope[];
  is_revoked: boolean;
  status: ChannelTokenStatus;
  error: string | null;
  revoked_at: string | null;
};

export type ChannelBaseServer = {
  _id: string;
  external_id: string;
  domain: string;
  name: string;
  url: string;
  users_total: number;
  tokens: ChannelTokenServer[];
  root_chat_id: string;
  extra?: {
    photo_url?: string;
  };
};

type ChannelExtraInfo = {
  manager_linked: boolean;
  scenario_ids: string[];
};

export type ChannelCommonServer = ChannelBaseServer &
  ChannelExtraInfo & {
    kind: CommonChannelKind;
  };

export type ChannelTgButtonServer = {
  text: string;
  url: string;
};

export type ChannelTgServer = ChannelBaseServer &
  ChannelExtraInfo & {
    kind: ChannelKind.TELEGRAM;
    extra?: {
      commands?: TgCommandServer[];
      menu_button?: ChannelTgButtonServer;
    };
  };

export type ChannelServer = ChannelCommonServer | ChannelTgServer;

export type ChannelEnabledServer = ChannelBaseServer & {
  enabled: boolean;
  kind: ChannelKind;
};

export type ListValidChannelsServer = {
  channels: {
    vk?: ChannelEnabledServer;
    tg?: ChannelEnabledServer;
  };
};

export interface IChannelPage {
  id: string;
  title: string;
  img: string;
}

export enum RunFromBlockChannelErrorCode {
  channelIsNotFound = 'channel_is_not_found',
  theManagerIsNotLinked = 'the_manager_is_not_linked',
  userProbablyWasDeleted = 'user_probably_was_deleted'
}

export const mapRunFromBlockChannelErrorCodeToMessage =
  (code: RunFromBlockChannelErrorCode): TranslationString =>
  (t) =>
    t(`channels.errors.runFromBlock.${code}`, {
      ns: 'entities'
    });

export enum CommandError {
  DUPLICATED_COMMANDS = 'duplicated_commands',
  CAN_NOT_SET_COMMANDS = 'can_not_set_commands'
}

export const mapCommandErrorCodeToMessage =
  (code: CommandError): TranslationString =>
  (t) =>
    t(`channels.errors.saveCommands.${code}`, {
      ns: 'entities'
    });

export enum CreateChannelError {
  invalidToken = 'invalid_token',
  channelAlreadyExist = 'channel_already_exists'
}

export const mapCreateChannelErrorToMessage =
  (code: CreateChannelError): TranslationString =>
  (t) =>
    t(`channels.errors.create.${code}`, {
      ns: 'entities'
    });

export enum CreateJivoError {
  invalidUrl = 'invalid_url',
  channelNotLinkedToJv = 'channel_not_linked_to_jv'
}

export enum CreateWazzupError {
  invalidTokenKind = 'invalid_token_kind'
}

export const mapCreateJivoChannelErrorToMessage = (
  code: CreateChannelError.channelAlreadyExist | CreateJivoError.invalidUrl
) => {
  if (code === CreateChannelError.channelAlreadyExist) {
    return mapCreateChannelErrorToMessage(code);
  }

  return (t) =>
    t(`channels.errors.createJivo.${code}`, {
      ns: 'entities'
    });
};

export const mapCreateWazzupChannelErrorToMessage = (
  code: CreateChannelError | CreateWazzupError
) => {
  if (code === CreateWazzupError.invalidTokenKind) {
    return (t) =>
      t(`channels.errors.createWazzup.${code}`, {
        ns: 'entities'
      });
  }

  return mapCreateChannelErrorToMessage(code);
};

export enum ExternalChannelKind {
  VK = 'vk',
  TELEGRAM = 'telegram',
  FB = 'facebook',
  IG = 'instagram',
  VB = 'viber',
  WHATSAPP = 'whatsapp',
  AVITO = 'avito',
  OK = 'odnoklassniki',
  ALIEXPRESS = 'aliExpress',
  WABA = 'wapi',
  TELEGRAM_PERSONAL = 'tgapi'
}

export const mapChannelKindToExternalChannelKind: Record<
  ExternalChannelKindType,
  ExternalChannelKind
> = {
  [ChannelKind.VK]: ExternalChannelKind.VK,
  [ChannelKind.FB]: ExternalChannelKind.FB,
  [ChannelKind.IG]: ExternalChannelKind.IG,
  [ChannelKind.VB]: ExternalChannelKind.VB,
  [ChannelKind.WHATSAPP]: ExternalChannelKind.WHATSAPP,
  [ChannelKind.AVITO]: ExternalChannelKind.AVITO,
  [ChannelKind.OK]: ExternalChannelKind.OK,
  [ChannelKind.ALIEXPRESS]: ExternalChannelKind.ALIEXPRESS,
  [ChannelKind.TELEGRAM]: ExternalChannelKind.TELEGRAM,
  [ChannelKind.TELEGRAM_PERSONAL]: ExternalChannelKind.TELEGRAM_PERSONAL,
  [ChannelKind.WABA]: ExternalChannelKind.WABA
};
