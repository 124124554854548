import { action, computed, makeObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';

import { IRootStore } from 'shared/entities/store/rootStore';
import { CabinetEditModel } from 'shared/models/cabinet';
import { ProjectEditModel } from 'shared/models/project';
import {
  CabinetCategory,
  DomainCheckingError,
  mapDomainCheckingErrorToMessage
} from 'shared/entities/cabinet';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { apiUrls } from 'shared/entities/domain';
import PubSubObserver from 'lib/PubSubObserver';

const DEBOUNCE_DELAY = 1000;
export default class CabinetCreationStore extends PubSubObserver {
  private readonly _rootStore: IRootStore;
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();

  readonly cabinetEditModel: CabinetEditModel;
  readonly projectEditModel: ProjectEditModel;

  readonly generatingDomainStage: LoadingStageModel = new LoadingStageModel();

  readonly showedGenerationButton: FieldModel<boolean> =
    new FieldModel<boolean>(false);

  readonly generatedDomainName: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);

  private readonly _newUser: boolean;

  constructor({
    rootStore,
    newUser
  }: {
    rootStore: IRootStore;
    newUser: boolean;
  }) {
    super();
    this._rootStore = rootStore;
    //newUser === true: создание кабинета с регистрации
    //newUser === false: создание кабинета существующим юзером
    this._newUser = newUser;
    const { t } = this._rootStore.translationsStore;
    this.cabinetEditModel = new CabinetEditModel();
    this.projectEditModel = new ProjectEditModel({
      name: t('CabinetCreationStore.mainProject', {
        ns: 'stores'
      })
    });

    makeObservable<CabinetCreationStore>(this, {
      buttonDisabled: computed,

      createInitialData: action.bound
    });

    this.subscribe();
  }

  get buttonDisabled(): boolean {
    return this.cabinetEditModel.isError || this.creatingStage.isLoading;
  }

  validateName = (): void => {
    this.cabinetEditModel.name.validate();
  };

  generateDomainOnChangeName = debounce(() => {
    if (this.cabinetEditModel.isNameEmpty) {
      return;
    }

    const name = this.cabinetEditModel.name.value;

    for (let i = 0; i < this.cabinetEditModel.name.validators.length; i++) {
      const error = this.cabinetEditModel.name.validators[i](name);
      if (error) {
        return;
      }
    }

    // если еще не генерировался домен и поле домена пустое, генерируем домен автоматически
    if (
      !this.generatedDomainName.value &&
      !this.cabinetEditModel.domain.value.length
    ) {
      this.generateDomain();
      return;
    }
    // если изменили название кабинета/компании, показываем кнопку перегенерации
    if (!this.showedGenerationButton.value) {
      this.showedGenerationButton.changeValue(true);
    }
  }, DEBOUNCE_DELAY);

  generateDomainOnEnter = () => {
    if (
      this.cabinetEditModel.isNameEmpty ||
      this.generatedDomainName.value ||
      this.cabinetEditModel.domain.value.length
    ) {
      return;
    }

    const name = this.cabinetEditModel.name.value;

    for (let i = 0; i < this.cabinetEditModel.name.validators.length; i++) {
      const error = this.cabinetEditModel.name.validators[i](name);
      if (error) {
        return;
      }
    }

    this.generateDomain();
  };

  onChangeDomain = debounce(() => {
    // если кнопки еще нет и домен был сгенерирован хотя бы 1 раз и в поле ввода его изменяют, показываем кнопку
    if (
      !this.showedGenerationButton.value &&
      this.generatedDomainName.value &&
      this.generatedDomainName.value !== this.cabinetEditModel.domain.value
    ) {
      this.showedGenerationButton.changeValue(true);
    }
  }, 200);

  generateDomain = async (): Promise<BaseResponse> => {
    if (
      this.generatingDomainStage.isLoading ||
      this.cabinetEditModel.isNameEmpty
    ) {
      return { isError: true };
    }

    this.generatingDomainStage.loading();

    const response = await this._rootStore.networkStore.api<{ domain: string }>(
      apiUrls.CABINETS_GENERATE_DOMAIN_NAME,
      {
        method: 'POST',
        data: {
          name: this.cabinetEditModel.name.value
        }
      }
    );

    if (!response.isError) {
      this.generatedDomainName.changeValue(response.data.domain);
      this.cabinetEditModel.domain.changeValue(response.data.domain);
      this.generatingDomainStage.success();
    } else {
      this.generatingDomainStage.error();
    }

    return {
      isError: response.isError
    };
  };

  async createInitialData(): Promise<BaseResponse> {
    if (this.creatingStage.isLoading) {
      return { isError: true };
    }

    this.cabinetEditModel.validate();

    if (this.cabinetEditModel.isError || this.creatingStage.isLoading) {
      return { isError: true };
    }

    const cabinetResponse = await this._rootStore.cabinetsStore.create(
      this.cabinetEditModel
    );

    if (cabinetResponse.isError) {
      this.creatingStage.error();

      if (cabinetResponse.data) {
        if (
          Object.values(DomainCheckingError).includes(
            cabinetResponse.data as DomainCheckingError
          )
        ) {
          if (
            cabinetResponse.data === DomainCheckingError.domain_taken &&
            !this.cabinetEditModel.isNameEmpty
          ) {
            this.showedGenerationButton.changeValue(true);
            this.cabinetEditModel.domain.setError((t) =>
              t('cabinet.errors.checkingDomain.domain_taken_with_instruction', {
                ns: 'entities'
              })
            );
            return {
              isError: true
            };
          }

          const errorText = mapDomainCheckingErrorToMessage(
            cabinetResponse.data as DomainCheckingError
          );

          if (errorText) {
            this.cabinetEditModel.domain.setError(errorText);
          }
        }
      }

      return {
        isError: true
      };
    }

    this._rootStore.analyticsStore.sendEvent(
      AnalyticsEvent.authCabinetCreationOk,
      {
        category: CabinetCategory.other,
        //данные передаем, только если регистрируется новый юзер
        auth_a_b: this._newUser ? this._rootStore.aBStore.authAB : undefined
      }
    );

    const cabinetInitResponse = await this._rootStore.initCabinet(
      cabinetResponse.data.cabinet.domain
    );

    if (cabinetInitResponse.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    const projectResponse = await this._rootStore.projectsStore.create({
      projectEditModel: this.projectEditModel
    });

    if (projectResponse.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    this.creatingStage.success();

    this._rootStore.routerStore.changeSubDomain({
      subDomain: cabinetResponse.data.cabinet.domain
    });

    return {
      isError: false
    };
  }

  subscribe = (): void => {
    this.addReaction({
      key: 'nameValue',
      reaction: reaction(
        () => this.cabinetEditModel.name.value,
        this.generateDomainOnChangeName
      )
    });
    this.addReaction({
      key: 'domainValue',
      reaction: reaction(
        () => this.cabinetEditModel.domain.value,
        this.onChangeDomain
      )
    });
  };
}
